<template>
  <div class="preview-customField">
    <!--   HEADER   -->
    <div class="zq-page-title-wrapper d-flex">
        <h3 class="zq-page-title">{{ formData.name }} </h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>

    <CTabs class="zq--tabs">
      <CTab title="Custom Field">
        <CustomFieldDetails></CustomFieldDetails>
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import CustomFieldDetails from '@/generated/ziqni/views/customFields/CustomFieldDetails';
import {mapGetters} from "vuex";

import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import {customFields} from "@/config/descriptions/customFields"

export default {
  components: {
    CustomFieldDetails,
    IconWithTooltip
  },
  data() {
    return {
      descriptions: {
        ...customFields.preview
      },
      formData: {
        name: '',
      },
    };
  },
  computed: {
    ...mapGetters('customFields', ['customField']),
  },
  watch: {
    customField: {
      deep: true,
      handler: function (val) {
        this.formData = val;
      },
    },
  },
}
</script>

<style lang="scss">
.preview-customField {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>